import {codeToToken} from '@ekultur/authentication';
import {useEffect} from 'react';
import {useLocation, useMatch, useNavigate} from 'react-router-dom';
import {useAuthsDispatch, USER_LOGGED_IN} from './authsContext';

export const Callback = () => {
  const match = useMatch("/oauth2/callback")
  const location = useLocation();
  const dispatch = useAuthsDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(match) {
      const searchParams = new URLSearchParams(location.search);
      codeToToken({
        code: searchParams.get('code'),
        state: searchParams.get('state'),
        apiGateway: window._env_.REACT_APP_API_GATEWAY,
        onSuccess: data => {
          dispatch({ type: USER_LOGGED_IN });
          navigate(data.previousPath);
        },
        sameSite: window._env_.REACT_APP_ENVIRONMENT === 'beta'
      });
    }
  }, [dispatch, navigate, location, match]);

  return null;
};
